<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
         Maldives Culture
        </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap ">
          <div class="mx-2 text-left">
            <p class="my-8">
              <b> Religion </b>
             <br>
The religion of the State of the Maldives is Islam. Legally, if you're 18 and not a Muslim, you can buy and drink alcohol. However since the Maldives are an Islamic nation, alcohol is effectively banned from the local population. However, nearly all resorts and live aboard boats are licensed to serve alcohol.

            </p>   <p class="my-8">
              <b>Language </b>
           <br>
Dhivehi is the national language of the Maldives, Mostly all Maldivians speak and understand English.

            </p>
            <p class="my-8">
              <b>Food </b>
           <br>
The amazing Maldivian food is usually chilies’, onions, basil leaves, and lime juice are used with many recipes. Tuna and dishes from this fish are the main highlights of the Maldivian table. Particularly popular meat of skipjack tuna, tuna frigate, and yellowfin tuna. Wahoo, Mahi-Mahi, and the big-eyed scad are also favorites. Fish is fried, boiled, baked, smoked, dried, canned, and dried in the hot Maldivian sun.
            </p>

               <p class="my-8 ">
              <b>Dress Codes </b>
           <br>
In Maldives people like to 'dress up in the evening, but in the majority of islands, the dress code is very relaxed, and smart casual is the order of the day. For men, smart or tailored shorts, 3/4 or full-length lightweight trousers are all perfectly acceptable.            </p>


               <p class="my-8 mb-20">
              <b>Do’s & Dont’s in Maldives </b>
           <br>
<ul>
<li>	Do not touch or pick corals when diving or snorkeling. </li>
<li>	Be conscious of the delicate local environment. </li>
<li>	Do not dispose of plastic bags in the sea, it can be hazardous. </li>
<li>	Don't hug or kiss in the public – the Maldives is conservative when it comes to expressing physical displays of affection.</li>

    </ul>
            </p>

          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "CulturePage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
